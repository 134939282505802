<template>
  <div class="mb-4 mx-2 text-center">
    <p id="new-code-created-msg-213191032" class="mb-2">
      {{ staticText.newCodeCreatedMessage }}
    </p>
    <b-form-group id="code-label-20221625">
      <b-form-input
        id="code-20221625"
        v-model.trim="otp"
        :placeholder="staticText.codeLabel"
        :class="!!error ? 'password-error' : ''"
        type="text"
      ></b-form-input>
    </b-form-group>
    <p v-if="!!error" class="error-message my-2">
      {{ error }}
    </p>
    <a
      id="send-new-code-link-213191043"
      class="my-2 link"
      href=""
      @click.prevent="createNewCode"
    >
      {{ staticText.createCodeBtnLabel }}
    </a>
    <b-form-group id="code-submit-btn-213191037">
      <b-button
        type="submit"
        variant="primary"
        class="col-md-12"
        @click.prevent="verifyCode"
      >
        {{ staticText.codeVerifyBtn }}
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "TwoFactorLoginInput",
  data () {
    return {
      axios,
      staticText: {
        codeLabel: "Security Code",
        codeVerifyBtn: "Verify Code",
        createCodeBtnLabel: "Send New Code",
        newCodeCreatedMessage:
        "A security code was sent to your email. Please type it in the field below and click Verify Code.",
        emptyCodeErrorMsg: "Enter Security Code"
      },
      otp: ""
    }
  },
  props: {
    authData: {
      type: Object,
      required: true
    },
    error: {
      type: String,
      default: null
    }
  },
  methods: {
    createNewCode () {
      const authData = { ...this.authData }
      authData.createNewCode = true
      return this.axios
        .post("/api/token/", authData)
        .then(response => {
          if (response.status === 201) {
            authData.createNewCode = false
            this.$emit("update-error", null)
            this.$emit("update-message", response.data.message)
          }
        })
        .catch(err => {
          authData.createNewCode = false
          this.$emit("update-error", err.response.data.message)
        })
    },
    verifyCode () {
      if (this.otp.length === 0) {
        this.$emit("update-error", this.staticText.emptyCodeErrorMsg)
        return
      }
      this.$emit("verify-otp", this.otp)
    }
  }
}
</script>

<style lang="scss" scoped>

.form-input input:not(:placeholder-shown):valid {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.link {
  color: $slate80;
}

.password-error {
  border: 1px solid $orange80;
  border-radius: 12px;
}

.error-message {
  font-size: 12px;
  line-height: 14px;
  color: $orange;
}

</style>
