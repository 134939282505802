<template>
  <div class="recovery-card">
    <div class="recovery-card-header p-2">
      <div class="ml-3">
        <img src="../../../public/img/icons/key-icon.svg" class="mr-2"/>
        <span>{{ staticText.accountRecoveryLabel }}</span>
      </div>
    </div>
    <b-form v-if="!showEmailSent" class="recovery-card-body">
      <div class="mx-auto mb-4">
        <h4 class="mx-auto mb-3 h5 text-center font-weight-bold">
          {{ staticText.accountRecoveryLabel }}
        </h4>
        <p class="text-center">{{ staticText.accountRecoveryText }}</p>
      </div>

      <div class="mb-3">
        <b-form-group
          id="reset-password-email-label-202012231345"
          label-align="left"
          label-for="reset-password-email-202012231345"
          :invalid-feedback="staticText.emailInvalidFeedback"
        >
          <b-form-input
            id="reset-password-email-202012231345"
            v-model="emailForReset"
            type="email"
            aria-describedby="inputLiveHelp inputLiveFeedback"
            :state="emailForResetState"
            :placeholder="staticText.emailLabel"
          ></b-form-input>
        </b-form-group>
      </div>

      <div>
        <div v-if="sendingEmail">
          <div class="my-2 text-primary">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ staticText.sendingEmailLabel }}</strong>
          </div>
        </div>

        <div v-else class="d-flex justify-content-between">
          <b-btn
            variant="secondary"
            class="px-4 font-size-14"
            @click="returnToLoginScreen"
          >
            <i class="fas fa-chevron-left"></i>
            {{ staticText.backLabel }}
          </b-btn>

          <b-button
            type="submit"
            class="px-5 font-size-14"
            variant="primary"
            @click.prevent="submittedPasswordResetEmail"
          >
            {{ staticText.recoveryMailBtn }}
          </b-button>
        </div>
      </div>
    </b-form>

    <div v-else class="p-3">
      <b-alert variant="info" :show="true">
        {{ staticText.passwordRecoveryAlert }}
      </b-alert>
      <b-btn
        variant="secondary"
        class="px-4 font-size-14"
        @click="returnToLoginScreen"
      >
        <i class="fas fa-chevron-left"></i>
        {{ staticText.backLabel }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "ForgotPassword",
  data () {
    return {
      emailForReset: "",
      emailRegexTest: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showEmailSent: false,
      sendingEmail: false,

      staticText: {
        accountRecoveryLabel: "Account Recovery",
        accountRecoveryText:
        "You can recover your forgotten credentials by providing your account email address and submitting the form below.",
        emailInvalidFeedback: "Please enter a valid email address.",
        emailLabel: "Email",
        sendingEmailLabel: "Sending Email ...",
        recoveryMailBtn: "Send Recovery Mail",
        backLabel: "Back",
        passwordRecoveryAlert:
          "A password recovery email has been sent to your mail address.\n" +
          "            Please follow the provided instructions in the email."
      }
    }
  },
  props: {
    authDataEmail: {
      type: String,
      default: ""
    }
  },
  computed: {
    emailState () {
      if (this.authDataEmail === "") return null
      return this.emailRegexTest.test(this.authDataEmail)
    },
    emailForResetState () {
      return (
        !!this.emailForReset &&
        this.emailForReset !== "" &&
        this.emailRegexTest.test(this.emailForReset)
      )
    }
  },
  mounted () {
    if (this.authDataEmail) this.emailForReset = this.authDataEmail
  },
  methods: {
    returnToLoginScreen () {
      this.showEmailSent = false
      this.$emit("return-to-login-screen")
    },
    submittedPasswordResetEmail () {
      if (!this.emailForResetState) return

      this.sendingEmail = true
      axios
        .post("/api/users/password_reset", { email: this.emailForReset })
        .then(
          () => {
            this.showEmailSent = true
            this.sendingEmail = false
          },
          error => {
            this.$emit("update-error", error.response)
          }
        )
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.form-input input:not(:placeholder-shown):valid {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.recovery-card {
  width: 410px;
  background-color: $white;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 20px;

  .recovery-card-header {
    color: $slate80;
    background-color: $grey;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .recovery-card-body {
    padding: 40px;

    h4 {
      color: $black;
    }
  }
}

@media (max-width: 440px) {
  .recovery-card {
    width: 100%;
  }
}

</style>
